import React from "react";
import { Link } from "react-router-dom"; // Importamos Link de react-router-dom
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com"; // Se integra libreria EmailJS
import Select from 'react-select'

import s from "../styles/formulario.module.css";


function Formulario() {
    const options = [
        { value: 'Amigable', label: 'Amigable' },
        { value: 'Competitivo', label: 'Competitivo' },
        { value: 'Compasivo', label: 'Compasivo' },
        { value: 'Conservador', label: 'Conservador' },
        { value: 'Considerado', label: 'Considerado' },
        { value: 'Receptivo', label: 'Receptivo' },
        { value: 'Controlador', label: 'Controlador' },
        { value: 'Diplomático', label: 'Diplomático' },
        { value: 'Directo', label: 'Directo' },
        { value: 'Excéntrico', label: 'Excéntrico' },
        { value: 'Entusiasta', label: 'Entusiasta' },
        { value: 'Emprendedor', label: 'Emprendedor' },
        { value: 'Flexible', label: 'Flexible' },
        { value: 'Enfocado', label: 'Enfocado' },
        { value: 'Trabajador', label: 'Trabajador' },
        { value: 'Impaciente', label: 'Impaciente' },
        { value: 'Intuitivo', label: 'Intuitivo' },
        { value: 'Independiente', label: 'Independiente' },
        { value: 'Líder', label: 'Líder' },
        { value: 'Leal', label: 'Leal' },
        { value: 'Persuasivo', label: 'Persuasivo' },
        { value: 'Confiable', label: 'Confiable' },
        { value: 'Arriesgado', label: 'Arriesgado' },
        { value: 'Espontáneo', label: 'Espontáneo' },
        { value: 'Pensador', label: 'Pensador' },
        { value: 'Tradicional', label: 'Tradicional' },
        { value: 'Comprensivo', label: 'Comprensivo' },
        { value: 'Único', label: 'Único' },
        { value: 'Voluntarioso', label: 'Voluntarioso' },
        { value: 'Práctico', label: 'Práctico' },
    ]

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // Prepara los datos para que coincidan con las variables de la plantilla de EmailJS
    const templateParams = {
      to_name: data.nombre, // Puedes establecer un valor estático o agregar un campo de entrada para el nombre del destinatario
      from_name: data.nombre,
      message: `
        Nombre: ${data.nombre}
        Fecha de Nacimiento: ${data.fechaNacimiento}
        Dirección: ${data.direccion}
        Ciudad: ${data.ciudad}
        Estado: ${data.estado}
        Código Postal: ${data.codigoPostal}
        Celular: ${data.celular}
        Email: ${data.email}
        Teléfono Oficina: ${data.telefonoOficina}
        Otro Contacto: ${data.otroContacto}
        Casa Propia: ${data.casaPropia}
        Estado Civil: ${data.estadoCivil}
        Dependientes: ${data.dependientes}
        Ocupación del Cónyuge: ${data.ocupacionConyuge}
        Grado de Estudios: ${data.gradoEstudios}
        Empleo Actual: ${data.empleoActual}
        Puesto y Responsabilidades: ${data.puestoResponsabilidades}
        Ingreso Anual Comprobable: $${data.ingresoAnual}
        Capacidad de Inversión Actual: $${data.inversionActual}
        Ciudades de Interés: ${data.ciudadesInteres}
        Tipo de Unidad de Interés: ${data.tipoUnidad}
        Locales Identificados: ${data.localesIdentificados}
        Experiencia en Negocios: ${data.experienciaNegocios}
        Negocios Propios: ${data.negociosPropios}
        Qué le atrae de nuestra franquicia: ${data.atraeFranquicia}
        Otros Giros Analizados: ${data.otrosGiros}
        Capital a Invertir: ${data.capitalInvertir}
        Tipo de Inversionista: ${data.tipoInversionista}
        Plazo para Empezar a Invertir: ${data.plazoInversion}
        Reserva de Capital Adicional: ${data.reservaCapital}
        Plazo para Recuperar Inversión: ${data.plazoRecuperacion}
        Participación Activa en Operación: ${data.tipoFranquiciatario}
        Alternativas de Inversión: ${data.tipoInversion}
        Atributos: ${data.atributos}
        Valores: ${data.valores}
        Comentarios adicionales: ${data.adicional}
      `,
    };
    /*"service_dh1lxrl",
        "template_ucq027r",
        templateParams,
        "dQ-qyomE2r87HdRpM" */
    emailjs
      .send(
        "service_vibivzl",
        "template_rq3nv4g",
        templateParams,
        "TKhdV4YC-p6gALxxX"
      )
      .then((response) => {
        const msg = document.getElementById('success__form');
        msg.style.display = 'block';
        console.log("Correo enviado con éxito", response.status, response.text);
      })
      .catch((error) => {
        const msg = document.getElementById('error__form');
        msg.style.display = 'block';
        console.error("Error al enviar el correo", error);
      });
  };

  const validateAdult = (value) => {
    const today = new Date();
    const birthDate = new Date(value);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      return age - 1 >= 18 ? true : "Debes ser mayor de edad";
    }

    return age >= 18 ? true : "Debes ser mayor de edad";
  };

  return (
    <div className={s.formulario}>
      <h1 className={s.title}>PRE-SOLICITUD DE FRANQUICIA</h1>

      <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
        <div className={s.flex}>
          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                {...register("nombre", {
                  required: "Este campo es obligatorio",
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Solo se permiten letras y espacios",
                  },
                })}
              />
              {errors.nombre && <p>{errors.nombre.message}</p>}
              <span className={s.form__text}>Nombre</span>
            </label>
          </div>

          <div className={s.form__inputs}>
            <label className={s.form__label}>
              {" "}
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                type="date"
                {...register("fechaNacimiento", {
                  required: "Este campo es obligatorio",
                  validate: validateAdult,
                })}
              />
              {errors.fechaNacimiento && (
                <p>{errors.fechaNacimiento.message}</p>
              )}
            </label>
          </div>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <input
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("direccion", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.direccion && <p>{errors.direccion.message}</p>}
            <span className={s.form__text}>Dirección</span>
          </label>
        </div>

        <div className={s.flex2}>
          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                {...register("ciudad", {
                  required: "Este campo es obligatorio",
                  pattern: {
                    value: /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]+$/,
                    message: "Solo se permiten letras, espacios y acentos",
                  },
                })}
              />
              {errors.ciudad && <p>{errors.ciudad.message}</p>}
              <span className={s.form__text}>Ciudad</span>
            </label>
          </div>

          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                {...register("estado", {
                  required: "Este campo es obligatorio",
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Solo se permiten letras y espacios",
                  },
                })}
              />
              {errors.estado && <p>{errors.estado.message}</p>}
              <span className={s.form__text}>Estado</span>
            </label>
          </div>

          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                {...register("codigoPostal", {
                  required: "Este campo es obligatorio",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Solo se permiten números",
                  },
                  minLength: {
                    value: 5,
                    message:
                      "El código postal debe tener exactamente 5 dígitos",
                  },
                  maxLength: {
                    value: 5,
                    message:
                      "El código postal debe tener exactamente 5 dígitos",
                  },
                })}
              />
              {errors.codigoPostal && <p>{errors.codigoPostal.message}</p>}
              <span className={s.form__text}>Código postal</span>
            </label>
          </div>
        </div>

        <div className={s.flex}>
          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                type="tel"
                {...register("celular", {
                  required: "Este campo es obligatorio",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Solo se permiten números",
                  },
                  minLength: {
                    value: 10,
                    message: "El celular debe tener exactamente 10 dígitos",
                  },
                  maxLength: {
                    value: 10,
                    message: "El celular debe tener exactamente 10 dígitos",
                  },
                })}
              />
              {errors.celular && <p>{errors.celular.message}</p>}
              <span className={s.form__text}>Celular</span>
            </label>
          </div>

          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                type="email"
                {...register("email", {
                  required: "Este campo es obligatorio",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Correo electrónico no es válido",
                  },
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
              <span className={s.form__text}>Correo electrónico</span>
            </label>
          </div>
        </div>

        <div className={s.flex}>
          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                type="tel"
                {...register("telefonoOficina", {
                  required: "Este campo es obligatorio",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Solo se permiten números",
                  },
                  minLength: {
                    value: 10,
                    message:
                      "El teléfono de oficina debe tener exactamente 10 dígitos",
                  },
                  maxLength: {
                    value: 10,
                    message:
                      "El teléfono de oficina debe tener exactamente 10 dígitos",
                  },
                })}
              />
              {errors.telefonoOficina && (
                <p>{errors.telefonoOficina.message}</p>
              )}
              <span className={s.form__text}>Teléfono oficina</span>
            </label>
          </div>

          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                {...register("otroContacto", {
                  required: "Este campo es obligatorio",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Solo se permiten números",
                  },
                  minLength: {
                    value: 10,
                    message: "El contacto debe tener exactamente 10 dígitos",
                  },
                  maxLength: {
                    value: 10,
                    message: "El contacto debe tener exactamente 10 dígitos",
                  },
                })}
              />
              {errors.otroContacto && <p>{errors.otroContacto.message}</p>}
              <span className={s.form__text}>Otro medio de contacto</span>
            </label>
          </div>
        </div>

        <div className={s.flex2}>
          <div className={s.form__inputs}>
            <label className={s.form__label}>
              {" "}
              <select
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                {...register("casaPropia", {
                  required: "Este campo es obligatorio",
                })}
              >
                <option value="">Seleccione una opción</option>
                <option value="Si">Si</option>
                <option value="Rentada">Rentada</option>
                <option value="Familiar">Familiar</option>
                <option value="Otro">Otro</option>
              </select>
              {errors.casaPropia && <p>{errors.casaPropia.message}</p>}
              <span className={s.form__text}>Casa Propia</span>
            </label>
          </div>

          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <select
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                {...register("estadoCivil", {
                  required: "Este campo es obligatorio",
                })}
              >
                <option value="">Seleccione una opción</option>
                <option value="Casado">Casado</option>
                <option value="Soltero">Soltero</option>
                <option value="Union Libre">Unión Libre</option>
                <option value="Divorciado">Divorciado</option>
                <option value="Viudo">Viudo</option>
              </select>
              {errors.estadoCivil && <p>{errors.estadoCivil.message}</p>}
              <span className={s.form__text}>Estado Civil</span>
            </label>
          </div>

          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                type="number"
                {...register("dependientes", {
                  required: "Este campo es obligatorio",
                  validate: {
                    nonNegative: (value) =>
                      value >= 0 || "No puede ser un número negativo",
                  },
                })}
              />
              {errors.dependientes && <p>{errors.dependientes.message}</p>}
              <span className={s.form__text}>
                Número de dependientes económicos
              </span>
            </label>
          </div>
        </div>

        <div className={s.flex}>
          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                {...register("ocupacionConyuge", {
                  required: "Este campo es obligatorio",
                })}
              />
              {errors.ocupacionConyuge && (
                <p>{errors.ocupacionConyuge.message}</p>
              )}
              <span className={s.form__text}>Ocupación del cónyuge</span>
            </label>
          </div>

          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <select
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                {...register("gradoEstudios", {
                  required: "Este campo es obligatorio",
                })}
              >
                <option value="">Seleccione una opción</option>
                <option value="Secundaria">Secundaria</option>
                <option value="Profesional">Profesional</option>
                <option value="Bachillerato">Bachillerato</option>
                <option value="Posgrado">Posgrado</option>
                <option value="Otro">Otro</option>
              </select>
              {errors.gradoEstudios && <p>{errors.gradoEstudios.message}</p>}
              <span className={s.form__text}>
                Grado de estudios del solicitante
              </span>
            </label>
          </div>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <input
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("empleoActual", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.empleoActual && <p>{errors.empleoActual.message}</p>}
            <span className={s.form__text}>Empleo o empresa actual</span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("puestoResponsabilidades", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.puestoResponsabilidades && (
              <p>{errors.puestoResponsabilidades.message}</p>
            )}
            <span className={s.form__text}>
              Puesto y principales responsabilidades
            </span>
          </label>
        </div>

        <div className={s.flex}>
          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                type="number"
                {...register("ingresoAnual", {
                  required: "Este campo es obligatorio",
                  validate: (value) =>
                    value > 0 || "El ingreso anual debe ser un número positivo",
                })}
              />
              {errors.ingresoAnual && <p>{errors.ingresoAnual.message}</p>}
              <span className={s.form__text}>
                Ingreso promedio anual comprobable
              </span>
            </label>
          </div>

          <div className={s.form__inputs}>
            <label className={s.form__label}>
              <input
                className={s.form__input}
                placeholder=" " // Agrega un placeholder vacío
                type="number"
                {...register("inversionActual", {
                  required: "Este campo es obligatorio",
                  validate: (value) =>
                    value > 0 || "El ingreso anual debe ser un número positivo",
                })}
              />
              {errors.inversionActual && (
                <p>{errors.inversionActual.message}</p>
              )}
              <span className={s.form__text}>
                Capacidad de inversión actual
              </span>
            </label>
          </div>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <input
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("ciudadesInteres", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.ciudadesInteres && <p>{errors.ciudadesInteres.message}</p>}
            <span className={s.form__text}>Ciudades de interés</span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <select
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("tipoUnidad", {
                required: "Este campo es obligatorio",
              })}
            >
              <option value="">Seleccione una opción</option>
              <option value="Local en plaza comercial">
                Local en plaza comercial
              </option>
              <option value="Unidad independiente">
                Unidad independiente (stand alone)
              </option>
              <option value="Local a pie de calle">Local a pie de calle</option>
              <option value="Otro">Otro</option>
            </select>
            {errors.tipoUnidad && <p>{errors.tipoUnidad.message}</p>}
            <span className={s.form__text}>
              Qué tipo de unidad le interesa operar
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("localesIdentificados", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.localesIdentificados && (
              <p>{errors.localesIdentificados.message}</p>
            )}
            <span className={s.form__text}>
              ¿Cuenta con locales o ubicaciones identificadas para operar?
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("experienciaNegocios", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.experienciaNegocios && (
              <p>{errors.experienciaNegocios.message}</p>
            )}
            <span className={s.form__text}>
              ¿Tiene alguna experiencia en la operación o administración de
              negocios? (describa en caso afirmativo)
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("negociosPropios", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.negociosPropios && <p>{errors.negociosPropios.message}</p>}
            <span className={s.form__text}>
              ¿Tiene o ha tenido negocios propios? (describa en caso afirmativo)
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("atraeFranquicia", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.atraeFranquicia && <p>{errors.atraeFranquicia.message}</p>}
            <span className={s.form__text}>
              ¿Qué le atrae de invertir en nuestra franquicia?
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("otrosGiros", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.otrosGiros && <p>{errors.otrosGiros.message}</p>}
            <span className={s.form__text}>
              ¿Señale otros giros de negocio que ha analizado con la intención
              de invertir?
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("capitalInvertir", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.capitalInvertir && <p>{errors.capitalInvertir.message}</p>}
            <span className={s.form__text}>
              ¿El capital a invertir es propio o dispone de financiamiento?
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("tipoInversionista", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.tipoInversionista && (
              <p>{errors.tipoInversionista.message}</p>
            )}
            <span className={`${s.form__text} ${s.textol}`}>
              ¿Piensa ser inversionista individual o formará una sociedad? (en
              caso de ser sociedad, mencione el No. de socios e incluya sus
              datos generales)
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <input
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("plazoInversion", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.plazoInversion && <p>{errors.plazoInversion.message}</p>}
            <span className={`${s.form__text} ${s.textol}`}>
              ¿En caso de ser aceptado como franquiciatario, qué plazo requiere
              para empezar a invertir?
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("reservaCapital", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.reservaCapital && <p>{errors.reservaCapital.message}</p>}
            <span className={`${s.form__text} ${s.textol}`}>
              ¿Está considerando contar con reserva para capital de trabajo
              adicional a la inversión inicial?
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("plazoRecuperacion", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.plazoRecuperacion && (
              <p>{errors.plazoRecuperacion.message}</p>
            )}
            <span className={s.form__text}>
              ¿Qué plazo considera aceptable para recuperar su inversión?
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <select
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("capitalInvertir", {
                required: "Este campo es obligatorio",
              })}
            >
              <option value="">Seleccione una opción</option>
              <option value="Propio">Propio</option>
              <option value="Financiamiento">Financiamiento</option>
            </select>
            {errors.capitalInvertir && <p>{errors.capitalInvertir.message}</p>}
            <span className={s.form__text}>
              ¿El capital a invertir es propio o dispone de financiamiento?
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("tipoFranquiciatario", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.tipoFranquiciatario && (
              <p>{errors.tipoFranquiciatario.message}</p>
            )}
            <span className={`${s.form__text} ${s.textol}`}>
              ¿Está pensando en ser franquiciatario inversionista o pretende
              participar activamente en la operación y administración del
              negocio?
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("tipoInversion", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.tipoInversion && <p>{errors.tipoInversion.message}</p>}
            <span className={`${s.form__text} ${s.textol}`}>
              ¿Ha analizado otras alternativas de inversión diferentes a una
              franquicia? (explique)
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("atributos", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.tipoFranquiciatario && (
              <p>{errors.atributos.message}</p>
            )}
            <span className={`${s.form__text} ${s.textol}`}>
              Escriba de 6 a 10 atributos que mejor lo describan
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("valores", {
                required: "Este campo es obligatorio",
              })}
            />
            {errors.tipoFranquiciatario && (
              <p>{errors.valores.message}</p>
            )}
            <span className={`${s.form__text} ${s.textol}`}>
              Escriba de 6 cosas que mejor describan sus valores personales
            </span>
          </label>
        </div>

        <div className={s.form__inputs}>
          <label className={s.form__label}>
            <textarea
              className={s.form__input}
              placeholder=" " // Agrega un placeholder vacío
              {...register("adicional")}
            />
            <span className={`${s.form__text} ${s.textol}`}>
              Comentarios adicionales
            </span>
          </label>
        </div>




        <div id="success__form" className={ s.success__form }>Mensaje enviado con éxito, pronto nos pondremos en contacto con usted.</div>
        <div id="error__form" className={ s.error__form }>Ocurrió un error al enviar el correo, vuelve a intentarlo.</div>

        <button className={s.btn__form} type="submit">Enviar</button>
      </form>

      <div className={s.terminos}>
        <p className={s.parrafo}>
          La presente información se considera <span className={s.spant}>CONFIDENCIAL</span> y solo será
          utilizada para fines de evaluación del perfil de prospectos a
          franquiciatarios. Palmarés se compromete a utilizar los datos
          proporcionados solo para los fines señalados y a no divulgarlos ni
          retransmitirlos a terceros.
        </p>
        <p>
          Puede acceder a nuestro <span className={s.spant}>AVISO DE PRIVACIDAD</span> en la siguiente liga:
          <a href="docs/Aviso de Privacidad-Palmares.pdf" target="_blank" className={s.links} to="/"> *******link al aviso********</a>
        </p>
      </div>
    </div>
  );
}

export default Formulario;
