import h from "../styles/home.module.css"
import t from "../styles/textos.module.css"
import cs from "classnames"
import { Image } from "./Images"

export default function Map({ city, address="", tel="", address_link="" }) {
    return (
        <div className={ h.map_element }>
            <Image src="map" alt="Mapa icon"/>
            <div className={ cs(h.map_element_city, t.map_title) }>{ city }</div>
            <a href={ address_link } target="_blank" className={ cs(h.map_element_address, t.map_address) }>{ address }</a>
            { tel != "" ? <a href={ "tel:" + tel } className={ cs(h.map_element_tel, t.map_tel) }>Tel: { tel }</a> : null }
        </div>
    )
}