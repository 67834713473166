import Navbar2 from "./Navbar2";
import { Footer } from "./Footer";
import f from "../styles/franquicias.module.css";
import cn from 'classnames';

function Franquicias() {
    return (
        <>
            <Navbar2/>
            <div className={ f.header }>
                <picture>
                    <source type="image/webp" srcSet={"/images/franquicias/header1920.webp"} media="(min-width:1400px)"/>
                    <source type="image/png" srcSet={"/images/franquicias/header1920.png"} media="(min-width:1400px)"/>
                    <source type="image/webp" srcSet={"/images/franquicias/header1500.webp"} media="(max-width:1399px) and (min-width: 1200px)"/>
                    <source type="image/png" srcSet={"/images/franquicias/header1500.png"} media="(max-width:1399px) and (min-width: 1200px)"/>
                    <source type="image/webp" srcSet={"/images/franquicias/header1200.webp"} media="(max-width:1199px) and (min-width: 900px)"/>
                    <source type="image/png" srcSet={"/images/franquicias/header1200.png"} media="(max-width:1199px) and (min-width: 900px)"/>
                    <source type="image/webp" srcSet={"/images/franquicias/header900.webp"} media="(max-width:899px) and (min-width: 600px)"/>
                    <source type="image/png" srcSet={"/images/franquicias/header900.png"} media="(max-width:899px) and (min-width: 600px)"/>
                    <img className={ f.headerImage } src={ "/images/franquicias/header600.png" } srcSet={ "/images/franquicias/header600.webp" } alt="Comida palmarés"/>
                </picture>
                <div className={ f.headerTitle }>¡Únete a nuestro grupo de inversionistas!</div>
            </div>
            
            <div className={ f.section }>
                <div className={ f.element }>
                    <div className={ f.elementDes }>
                        <div className={ f.elementDesTitle }>Una franquicia Palmarés te ofrece lo siguiente:</div>
                        <div className={ f.elementDesParagraph }>• Territorios completos por ciudad, lo que permite aprovechar la apertura de varias unidades conforme al potencial de mercado de la plaza otorgada.</div>
                        <div className={ f.elementDesParagraph }>• Asistencia técnica completa, la cual inicia con la evaluación de ubicaciones, estudios de macro y micro localización, anteproyecto y proyectos “llave en mano”.</div>
                        <div className={ f.elementDesParagraph }>• Acceso a un equipo de entrenamiento profesional dedicado 100% durante las etapas de preapertura y post apertura.</div>
                        <div className={ f.elementDesParagraph }>• Opción de que sus unidades sean manejadas por nuestra Operadora de Restaurantes.</div>
                        <div className={ f.elementDesParagraph }> •Acceso a especialistas en marketing para la campaña previa, inauguración y durante la operación cotidiana de su sucursal.</div>
                    </div>
                    <div className={ f.elementImage }>
                        <img src="/images/franquicias/sushi.png" alt="Sushi" />
                    </div>
                </div>
            </div>

            <div className={ f.section }>
                <div className={ f.element }>
                    <div className={ f.elementImage }>
                        <img src="/images/franquicias/restaurantes.png" alt="Restaurante" />
                    </div>
                    <div className={ f.elementDes }>
                        <div className={ f.elementDesParagraph }>• Proveeduría oportuna de productos clave y acceso a productos exclusivos.</div>
                        <div className={ f.elementDesParagraph }>• Asesoría especializada en controles operativos, administrativos, contables y de RR HH.</div>
                        <div className={ f.elementDesParagraph }>• Contamos con nuestro propio sistema punto de venta integrado en un ERP que abarca todos los aspectos de manejo del restaurante.</div>
                        <div className={ f.elementDesParagraph }>• Acceso a sistemas de supervisión a distancia.</div>
                        <div className={ cn(f.elementDesParagraph, f.elementDesParagraphTitle) }>REQUERIMIENTOS</div>
                        <div className={ f.elementDesParagraph }>• Capacidad aproximada de inversión 16 MDP.</div>
                        <div className={ f.elementDesParagraph }>• Demostrar arraigo y presencia empresarial en la ciudad donde desea operar sus restaurantes.</div>
                        <div className={ f.elementDesParagraph }>• Contar con disponibilidad propia o de asignar a una persona facultada para tomar decisiones durante el proceso de negociación.</div>
                        <div className={ f.elementDesParagraph }>• Proponer puntos y zonas de interés para la ubicación de su unidad.</div>
                        <div className={ f.elementDesParagraph }>• Enviar su pre-solicitud de franquicia con la información indicada en el formulario al final de esta página.</div>
                    </div>
                </div>
            </div>

            <div className={ f.section }>
                <div className={ f.element }>
                    <div className={ f.elementDes }>
                        <div className={ f.elementDesTitle }>Proceso de otorgamiento</div>
                        <div className={ f.elementDesParagraph }>1. Llenar la información de nuestra pre-solicitud de franquicia.</div>
                        <div className={ f.elementDesParagraph }>2. Firmar un acuerdo mutuo de confidencialidad.</div>
                        <div className={ f.elementDesParagraph }>3. Demostrar su capacidad de inversión.</div>
                        <div className={ f.elementDesParagraph }>4. Indicar el territorio o territorios de interés para abrir su primer restaurante.</div>
                        <div className={ f.elementDesParagraph }>5. Recibir nuestra Circular Oferta de Franquicia en la cual se establecen claramente las condiciones del contrato de franquicia.</div>
                        <div className={ f.elementDesParagraph }>6. Recibir la información financiera donde se detallan los montos de inversión, ROI y escenarios de rentabilidad.</div>
                        <div className={ f.elementDesParagraph }>7. Presentar carta de intención para formalizar su aprobación como franquiciatario.</div>
                        <div className={ f.elementDesParagraph }>8. Firmar el contrato de franquicia e iniciar la etapa de pre-apertura.</div>
                    </div>
                    <div className={ f.elementImage }>
                        <img src="/images/franquicias/ceviche.png" alt="Ceviche" />
                    </div>
                </div>
            </div>

            <div className={ f.solicitud }>
                <a href="/franquicias/solicitud" className={ f.solicitudButton }>PRE-SOLICITUD DE FRANQUICIA</a>
            </div>
            <Footer/>
        </>
    )
}

export default Franquicias;