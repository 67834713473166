import React from "react";
import Navbar2 from "./Navbar2";
import Formulario from "./Formulario";
import { Footer } from "./Footer";
import Map from "./Map";

import h from "../styles/home.module.css";


function FranquiciasSolicitud() {
  return (
    <>
      <Navbar2 />
      <Formulario />
      <div className={h.encontrarnos_container}>
        <Map
          city="Mazatlán"
          address_link="https://maps.app.goo.gl/XmWwyVPEs71K3nZw6"
          address="Av Camarón Sábalo 1096, Sábalo Country, Mazatlán, Sin."
          tel="(669) 194 0492"
        />
        <Map
          city="Los Mochis"
          address_link="https://maps.app.goo.gl/zJZZZ2PUoHMaREPJ6"
          address="Av. Heriberto Valdez # 1300, Colonia Scally, Los Mochis, Mexico"
          tel="(668) 385 1135"
        />
        <Map city="Próximamente en tu ciudad" />
      </div>
      <Footer />
    </>
  );
}

export default FranquiciasSolicitud;
