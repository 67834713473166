import g from "../styles/global.module.css"
import t from "../styles/textos.module.css"
import cs from "classnames"

import { Image } from "./Images"
import { Link } from "react-scroll"

export function Footer() {
    return(
        <div className={ g.footer_container }>
            <div className={ cs(g.footer, t.footer) }>
                <div className={ g.footer_logo }>
                    <Image src="navbar/logo"/>
                </div>
                <div className={ g.footer_menu }>
                    <Link to="nosotros">nosotros</Link>
                    <Link to="instalaciones">instalaciones</Link>
                    <a href='https://palmares.proyecta.com.mx/menu.pdf' target="_blank" className={ g.footer_menu_element }>menú</a>
                    <Link to="sucursales">sucursales</Link>
                </div>
                <div className={ g.footer_info }>
                    <a href="tel:6691940492" className={ g.footer_info_element }>Tel.(669) 194 0492</a>
                    <div className={ g.footer_info_element }>Isla Tres City Center</div>
                    <a href="https://maps.app.goo.gl/XmWwyVPEs71K3nZw6" target="_blank" className={ g.footer_info_element }>Av Camarón Sábalo 1096, Sábalo Country</a>
                    <div className={ cs(g.footer_info_element, g.footer_info_element_sm) }>
                        <a href="https://www.facebook.com/Palmares.Mazatlan?mibextid=LQQJ4d" target="_blank"></a>
                        <a href="https://instagram.com/palmares.mazatlan?igshid=OGQ5ZDc2ODk2ZA==" target="_blank"></a>
                        <a href="https://wa.link/ggkdh6" target="_blank"></a>
                        <Image src="social_media" alt="Redes sociales Palmarés"/>
                    </div>
                </div>
                
            </div>
        </div>
    )
}