import h from "../styles/home.module.css"
import t from "../styles/textos.module.css"
import cs from "classnames"

import { Image } from "./Images"

import { Link } from "react-scroll"

export default function Header() {
    return(
        <div className={ h.header_container }>
            <div className={ h.tenedor_r }><Image src="header/tenedor_rigth" alt="Tenedor con comida"/></div>
            <div className={ h.header_container_logo }>
                <Image src="header/logo_letras" alt="Logo letras Palmarés"/>
                <div className={ cs(h.header_container_buttons, t.header) }>
                    <Link to="nosotros">nosotros</Link>
                    <a href="https://wa.link/ggkdh6" target="_blank">reserva</a>
                </div>
            </div>
        </div>
    )
}

/*
    Iba abajo de tenedor_rigth
    <div className={ h.tenedor_l }><Image src="header/tenedor_left" alt="Tenedor con comida"/></div>
*/