import cs from "classnames";
import n from "../styles/navbar.module.css";

import { Link as ScrollLink } from "react-scroll"; // Renombramos esta importación
import { Link } from "react-router-dom"; // Importamos Link de react-router-dom

import { Image } from "./Images";

export function Navbar() {
  return (
    <div className={n.navbar_container}>
      <div className={cs(n.navbar_left, n.navbar_object)}>
        <ScrollLink to="nosotros" className={n.navbar_option}>nosotros</ScrollLink> 
        
        <ScrollLink to="instalaciones" className={n.navbar_option}>instalaciones</ScrollLink>
      </div>
      <div className={n.navbar_center}>
        <Link to="/"><Image src="navbar/logo" alt="Logo palmarés" /></Link>
      </div>
      <div className={cs(n.navbar_right, n.navbar_object)}>
        <a href="https://palmares.proyecta.com.mx/menu.pdf" target="_blank" className={n.navbar_option}>menú</a>
        <ScrollLink to="sucursales" className={n.navbar_option}>sucursales</ScrollLink>
        <Link to="/franquicias" className={n.navbar_option}>franquicias</Link> {/* Usa Link de react-router-dom */}
      </div>
    </div>
  );
}
