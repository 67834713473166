import { Image } from "./Images"
import h from "../styles/home.module.css"

export function MenuElement({ src, name}) {
    return (
        <div className={ h.menu_element }>
            <Image src={ src } alt={ name }/>
            <div className={ h.menu_element_title }>/{ name }</div>
        </div>
    )
}