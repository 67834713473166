import t from "../styles/textos.module.css";
import h from "../styles/home.module.css";
import cs from "classnames";

export function Subtitle({ des }) {
    return(
        <div className={ t.subtitle }>
            <span></span>{ des }
        </div>
    )
}

export function Title({ des }) {
    return(
        <div className={ cs(t.title, h.sabores) }>
            { des }
        </div>
    )
}

export function Description({ des }) {
    return(
        <div className={ t.des }>
            { des }
        </div>
    )
}