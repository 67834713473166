import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Image } from './components/Images';
import { Navbar } from "./components/Navbar"
import Header from './components/Header';
import { Description, Subtitle, Title } from './components/Paragraphs';
import { MenuElement } from './components/Menu';
import Map from './components/Map';
import { Footer } from './components/Footer';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import g from "./styles/global.module.css"
import h from "./styles/home.module.css"
import cn from 'classnames';

import FranquiciasSolicitud from './components/FranquiciasSolicitud'; // Asegúrate de que este componente exista y esté importado correctamente
import Franquicias from './components/Franquicias'; // Asegúrate de que este componente exista y esté importado correctamente

const Home = () => (
  <>
    <Navbar />
    <Header />
    <div id="nosotros" className={ cn(h.nosotros, g.section) }>
      <Image src="platillo_chef" alt="Chef con platillo en la mano"/>
      <div className={ h.nosotros_description }>
        <Subtitle des="ACERCA DE NOSOTROS"/>
        <Title des="Lugar auténtico, donde la tradición se fusiona con todo el sabor de Sinaloa"/>
      </div>
    </div>
    <div className={ cn(h.chef, g.section) }>
      <div className={ h.chef_description }>
        <Subtitle des="CHEF CARLOS ACOSTA"/>
        <Description des="Con una vasta experiencia y un profundo conocimiento de los ingredientes, nuestro talentoso chef es capaz de combinar sabores, texturas y técnicas culinarias de una manera única. Su creatividad no tiene límites, y constantemente busca inspiración en la tradición culinaria, en ingredientes frescos y de alta calidad para sorprender a los comensales con platos innovadores y exquisitos."/>
      </div>
      <Image src="chef" alt="Chef cocinando pollo"/>
    </div>
    <div id="instalaciones" className={ cn(h.sabores) }>
      <div className={ h.sabores_description }>
        <Subtitle des="NUESTRAS INSTALACIONES"/>
        <Title des="Sabores que no puedes perderte en un solo lugar"/>
      </div>
      <Carousel showArrows={ true } autoPlay={ true } infiniteLoop={ true } showThumbs={ false } interval={ 5000 }>
        <Image src="/slider/1" alt="Restaurante instalaciones"/>
        <Image src="/slider/2" alt="Restaurante instalaciones"/>
        <Image src="/slider/3" alt="Restaurante instalaciones"/>
        <Image src="/slider/4" alt="Restaurante instalaciones"/>
      </Carousel>
    </div>
    <div className={ cn(h.instalaciones, g.section) }>
      <Image src="restaurante2" alt="Instalaciones palmarés"/>
      <div className={ h.instalaciones_description }>
        <Description des="Las instalaciones son una maravilla arquitectónica y un deleite para los sentidos. Desde el momento en que cruzas su umbral, te sumerges en un ambiente de elegancia y sofisticación. El vestíbulo de diseño cuidadoso te da la bienvenida con una iluminación suave y una decoración contemporánea que combina a la perfección con elementos clásicos. El comedor principal ofrece un ambiente íntimo y acogedor, con vistas panorámicas desde nuestra terreza que añaden un toque especial a cada comida. Así como los dos privados a su disposición para esa comodidad que se merecen."/>
      </div>
    </div>
    <div className={ cn(h.menu, g.section) }>
      <div className={ h.menu_description }>
        <Subtitle des="MENÚ"/>
        <Description des="Palmarés es todo un viaje culinario que te llevará a través de los sabores más exquisitos. Cada plato es una obra maestra, preparada con pasión y maestría por nuestro chef estrella."/>
      </div>
      <div className={ h.menu_link }>
        <a href='https://palmares.proyecta.com.mx/menu.pdf' target='_blank'>Ver menú completo</a>
      </div>
    </div>
    <div className={ cn(h.menu_elements, g.section) }>
      <MenuElement src="sashimi" name="Sashimi de robalo"/>
      <MenuElement src="tiradito" name="Tiradito palmarés"/>
      <MenuElement src="camarones_roca" name="Camarones roca"/>
      <MenuElement src="pasta_concordia" name="Pasta concordia"/>
    </div>
    <div id="sucursales" className={ h.encontrarnos }>
      <Subtitle des="NUESTRAS SUCURSALES"/>
      <Title des="¿Sabes dónde encontrarnos?"/>
    </div>
    <div className={ h.encontrarnos_container }>
      <Map city="Mazatlán" address_link='https://maps.app.goo.gl/XmWwyVPEs71K3nZw6' address='Av Camarón Sábalo 1096, Sábalo Country, Mazatlán, Sin.' tel="(669) 194 0492"/>
      <Map city="Los Mochis" address_link= 'https://maps.app.goo.gl/zJZZZ2PUoHMaREPJ6' address='Av. Heriberto Valdez # 1300, Colonia Scally, Los Mochis, Mexico' tel="(668) 385 1135"/>
      <Map city="Próximamente en tu ciudad"/>
    </div>
    <Footer/>
  </>
);

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="franquicias" element={<Franquicias />} />
      <Route path="franquicias/solicitud" element={<FranquiciasSolicitud />} />
      {/* Agrega más rutas para otras páginas si es necesario */}
    </Routes>
  </Router>,
  document.getElementById('root')
);
